import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import iziToast from 'izitoast/dist/js/iziToast';
import jQuery from 'jquery';
import "bootstrap";
import "trix";
import "@rails/actiontext";
import { start } from './../app';
import { Tooltip, Popover, Collapse, Dropdown } from "bootstrap";
import "../css/application.scss";

window.iziToast = iziToast;
window.jQuery = jQuery
window.$ = jQuery

import 'bootstrap-datepicker';
import 'bootstrap/js/dist/dropdown';

//import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de';

Rails.start();
Turbolinks.start();
start();

// The stylesheet location we created earlier
require("../css/application.scss")

document.addEventListener("turbolinks:load", () => {
    feather.replace({ 'aria-hidden': 'true' })

    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })

    var collapseTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="collapse"]'))
    var collapseList = collapseTriggerList.map(function(collapseTriggerEl) {
        return new Collapse(collapseTriggerEl)
    })

    var dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
    var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
        return new Dropdown(dropdownToggleEl)
    })

    if(!!$.fn.datepicker)
      $(".datepicker").datepicker({ format: "dd.mm.yyyy", language: "de" })
    else
      console.warn("!!! Function datepicker is not defined !!!")
})